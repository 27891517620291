<template>
  <div class="confirm-container">
    <template v-if="showResult">
      <div class="card-info">
        <div class="header">
          <div class="left">
            <div class="img">
              <img
                src="https://api.mengjingloulan.com/storage/wechat/coupon/store-icon.png"
              />
            </div>
            <div>梦回楼兰</div>
          </div>
          <div class="right">有效期{{ couponCardInfo.expires_time }}天</div>
        </div>
        <div class="cover">
          <img :src="couponCardInfo.cover" />
        </div>
        <div class="card-footer">
          <div class="left">
            {{ couponCardInfo.card_name }}
          </div>
          <div class="right">
            <stepper-v1
              :value="number"
              :min="1"
              :max="couponCardInfo.total_number"
              @onChange="onStep"
            ></stepper-v1>
          </div>
        </div>
        <div class="price">
          <div class="original_price">
            总价：{{ couponCardInfo.original_price }}
          </div>
          <div class="money">需付款 ¥{{ couponCardInfo.price }}</div>
        </div>
      </div>

      <div class="order-info">
        <cell-v3 :hide-icon="true" class="cell border-bottom">
          <div slot="title" class="cell-title">领取位置</div>
          <div class="cell-action">我的礼品卡</div>
        </cell-v3>
        <cell-v3 :hide-icon="true" class="cell">
          <div slot="title" class="cell-title">商品金额</div>
          <div class="cell-action">¥{{ couponCardInfo.price }}</div>
        </cell-v3>
        <!--<cell-v3 :hide-icon="true" class="cell">-->
        <!--<div slot="title" class="cell-title">留言</div>-->
        <!--<div class="cell-action">-->
        <!--<nut-textinput-->
        <!--v-model="remark"-->
        <!--placeholder="请输入您的留言"-->
        <!--:clearBtn="false"-->
        <!--class="inputs"-->
        <!--/>-->
        <!--</div>-->
        <!--</cell-v3>-->
      </div>

      <div class="footer">
        <div class="total">总计：¥{{ couponCardInfo.price }}</div>
        <div class="button-container">
          <div class="button" @click="goPay">去结算</div>
        </div>
      </div>
    </template>

    <loading-v1 ref="loading"></loading-v1>
  </div>
</template>

<script>
import StepperV1 from "@/template/community/stepper/stepperV1";
import LoadingV1 from "@/template/community/loading/loadingV1";
import CellV3 from "@/template/community/cell/cellV3";

export default {
  name: "card-buy-confirm",
  components: { CellV3, LoadingV1, StepperV1 },
  mounted() {
    document.title = "确认订单";
    let query = this.$route.query;
    let number = query.number || 1;
    this.number = parseInt(number);
    this.card_id = query.card_id || 0;
    this.getCouponCardBuyPreview();
    this.canContinue = true;
  },
  data() {
    return {
      couponCardInfo: {},
      number: 1,
      card_id: 0,
      showResult: false,
      remark: "",
      canContinue: false,
    };
  },
  methods: {
    getCouponCardBuyPreview() {
      this.$refs.loading.show();
      this.$api.couponCard
        .couponCardBuyConfirm({ card_id: this.card_id, number: this.number })
        .then((response) => {
          this.couponCardInfo = response.data;
          this.$refs.loading.hide();
          this.showResult = true;
        });
    },
    onStep(e) {
      this.number = parseInt(e.value);
      this.$api.couponCard
        .couponCardBuyConfirm({ card_id: this.card_id, number: this.number })
        .then((response) => {
          this.couponCardInfo = response.data;
        });
    },
    goPay() {
      let data = {
        card_id: this.card_id,
        number: this.number,
        remark: this.remark,
      };

      if (this.canContinue) {
        this.canContinue = false;
        this.$api.couponCard
          .couponCardBuy(data)
          .then((response) => {
            //调用微信支付
            let params = {
              ...response.data,
              successTitle: "支付成功",
              successText: "恭喜您，订单支付成功",
              cardId: this.card_id,
            };
            let options = encodeURIComponent(JSON.stringify(params));
            this.$platform.wxsdk.wxRoute({
              type: "redirectTo",
              url: "/web/money/coupon-card-pay?query=" + options,
            });

            setTimeout(() => {
              this.canContinue = true;
            }, 500);
          })
          .catch((error) => {
            this.$toast.fail(error.message);
            this.canContinue = true;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.confirm-container {
  width: 100vw;
  height: calc(100vh - 10px);
  background: #f6f6f6;
  padding-top: 10px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .card-info {
    background: #fff;
    padding: 18px 15px 15px 15px;
    display: flex;
    flex-wrap: wrap;

    .header {
      width: 100%;
      display: flex;
      align-items: center;

      .left {
        height: 19px;
        flex: 1;
        color: #333333;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;

        .img {
          img {
            width: 19px;
            height: 19px;
            margin-right: 2px;
          }
        }
      }

      .right {
        flex: 1;
        color: #939393;
        font-size: 12px;
        font-weight: 100;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .cover {
      width: 100%;
      height: 155px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      overflow: hidden;
      margin-top: 13px;

      img {
        width: 100%;
        height: 155px;
      }
    }

    .card-footer {
      width: 100%;
      display: flex;
      margin-top: 15px;

      .left {
        flex: 1;
        color: #333333;
        font-size: 12px;
      }

      .right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }

    .price {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 15px;

      .original_price {
        color: #a2a2a2;
        font-size: 14px;
        text-decoration: line-through;
        margin-right: 10px;
      }

      .money {
        color: #f46a17;
        font-size: 14px;
      }
    }
  }

  .order-info {
    background-color: #fff;
    padding: 15px 15px 10px 15px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;

    .border-bottom {
      border-bottom: #f6f6f6 solid 1px;
    }

    .cell {
      width: 100%;
      height: 40px;

      .cell-title {
        color: #333333;
        font-size: 14px;
      }

      .cell-action {
        font-size: 14px;
        color: #333333;
        font-weight: bold;

        /deep/ .nut-textinput input {
          border: none;
        }
      }
    }
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 56px;
    background: #fff;
    border-top: #f6f6f6 solid 1px;
    display: flex;
    align-items: center;
    padding: 0 15px;

    .total {
      flex: 1;
      color: #333333;
      font-size: 14px;
    }

    .button-container {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .button {
        display: flex;
        width: 115px;
        height: 36px;
        background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
        -webkit-border-radius: 36px;
        -moz-border-radius: 36px;
        border-radius: 36px;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
</style>